.passwordContainer {
    position: relative;
    top: 50%;
    left: 50%;
    z-index: 300;
    transform: translate(-50%, -50%);
    outline: 0;
    max-width: 474px;
}

.closeContainer {
    position: absolute;
    z-index: 1;
    right: 75px;
    top: 30px;
}

.endIcon {
    position: absolute;
    right: 20px;
    display: flex;
    justify-content: center;
    align-items: center;
}

.tabContainer {
    display: flex;
    flex-direction: row;
}

.customTab {
    flex: 1
}

.historyTab {
    flex: 0;
    margin-left: 9px;
    display: block;
    align-self: center;
}

.modalContainer {
    position: absolute;
    top: 50%;
    left: 50%;
    z-index: 300;
    transform: translate(-50%, -50%);
    max-width: 500px;
    width: calc(100% - 15px);
    outline: 0;
}