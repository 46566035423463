.box {
  background-color: var(--mui-palette-bg-secondary);
  border: 1px solid #2d346e;
  width: 100%;
  box-sizing: border-box;
  color: var(--mui-palette-text-secondary);
  border-radius: 6px;
  margin-bottom: 12px;
}

.dateRow {
  box-sizing: border-box;
  padding: 10px 14px 10px;
  border-bottom: 0.5px solid #2d346e;
  font-size: var(--text-md);
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.content {
  padding: 10px 14px 17px;
  display: flex;
  flex-direction: column;
}

.id {
  font-size: var(--text-base);
  position: relative;
  display: flex;
}

.idRight {
  position: absolute;
  right: 2px;
}

.num {
  display: inline;
  color: var(--mui-palette-text-primary);
  font-weight: 700;
  font-size: 18px;
  margin-top: 9px;
}

.balanceChanges {
  font-size: var(--text-md);
  margin-top: 9px;
  margin-bottom: 9px;
}

.balanceChanges {
  line-height: 20px;
}

.success {
  color: var(--mui-palette-text-success2);
}

.fail {
  color: var(--mui-palette-text-danger2);
}
