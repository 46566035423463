.vipIcon {
  height: auto;
  margin: auto;
}
.vipName {
  color: #fde266;
  font-size: 13;
  font-weight: 600;
}
.vipItem {
  display: flex;
  flex-direction: column;
  align-items: center;
}

.level0 {
  color: #aa8c5c;
}

.level1 {
  color: #ffb27d;
}

.level2 {
  color: #93a6c4;
}

.level3 {
  color: #fde266;
}

.level4,
.level5,
.level6,
.level7,
.level8,
.level9 {
  color: #62d2e3;
}

.level10 {
  color: #dd76d0;
}

/* .vipIcon0 {
  width: 85%;
  height: 85%;
} */
