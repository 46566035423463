.modalContainer {
  background-color: var(--mui-palette-bg-tertiary);
  position: relative;
  top: 50%;
  left: 50%;
  z-index: 300;
  transform: translate(-50%, -50%);
  outline: 0;
  max-width: 75%;
  border-radius: 10px;
  display: flex;
  flex-direction: column;
  padding: 30px;
  gap: 10px;
  color: white;
}

.titleImage {
  margin-right: 5px;
}

.title {
  flex: 1;
  margin: 0px;
  font-size: 20px;
  font-weight: 800;
  line-height: 22px;
}

.message {
  margin-top: 5px;
  justify-content: center;
  flex: 1;
  font-size: var(--text-md);
  font-weight: 400;
}

.buttonContainer {
  flex: 1;
  display: flex;
  flex-direction: row;
  gap: 20px;
  margin-top: 10px;
}

.submitBtn {
  flex: 1;
}

.cancelBtn {
  flex: 1;
  color: var(--mui-palette-text-primary) !important;
  background: linear-gradient(180deg, #12162a 0%, #2b3045 100%) !important;
}
