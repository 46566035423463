.drawer {
  width: 100%;
  display: flex;
  flex-direction: column;
  gap: 5px;
  padding-bottom: 140px;
}

.headerContainer {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 0px 12px;
  margin-top: 11px;
}

/* .leftHeader {
} */

.vipContainer {
  border: 2.2px solid;
  border-width: 2.2px;
  border-radius: 4px;
  border-image-slice: 1;
  border-image-source: linear-gradient(180deg, #5c7aa7 -1.27%, #313f63 101.28%);
  background: linear-gradient(180deg, #111031 -13.44%, #1131a4 71.19%),
    linear-gradient(180deg, #2c3055 -149.06%, #073386 176.29%, #6490ff 234.38%);
  background-blend-mode: screen;
}

.vipIcon {
  max-width: 50px;
  max-height: 50px;
  height: auto;
  aspect-ratio: 20/13;
  object-fit: contain;
}

.closeContainer {
  margin-left: auto;
  position: absolute;
  /* top: 20px; */
  right: 20px;
}

.closeButton {
  width: 24px;
  height: 24px;
  border-radius: 50%;
  display: flex;
  align-items: center;
  justify-content: center;
  /* background-color: #bbbbbb; */
}

.accordion {
  margin-bottom: 5px;
  color: var(--mui-palette-text-primary);
}

.accordion.Mui-expanded {
  color: blue !important;
}

.iconContainer {
  width: 32px;
  display: flex;
  justify-content: center;
  align-items: center;
}

.iconImg {
  height: auto;
  /* margin-right: 10px; */
}

.subItem {
  display: flex;
  /* padding: 8px 0px 8px 45px; */
  gap: 5px;
}

.subIconImg {
  width: 23px;
  height: 23px;
}

:global(.active) .subItem {
  font-weight: 700;
}

.subItem:focus {
  background-color: var(--mui-palette-bg-tertiary);
  border-radius: 36px;
}

.subIcon {
  height: auto;
  /* margin-right: 10px; */
}

.subIconList {
  margin-right: 6px;
  align-items: center;
  display: flex;
}

.typo {
  display: flex;
  align-items: center;
  font-size: var(--text-md);
  font-weight: 400;
}

.typotitle {
  font-size: var(--text-md);
  font-weight: 300;
  display: flex;
  margin-left: 3px;
  font-family: 'OpenSans', sans-serif;
}

.sidebarBottom {
  background-color: var(--mui-palette-bg-primary);
  bottom: 0;
  box-sizing: border-box;
  /* height: 75px; */
  left: 0;
  max-width: 700px;
  padding: 10px 20px;
  position: fixed;
  right: 0;
  z-index: 9999;
  box-sizing: border-box;
}

.languageContainer {
  position: relative;
  color: var(--mui-palette-text-secondary);
  font-size: var(--text-md);
  letter-spacing: 0.04em;
  border: 0.5px solid #939bb1;
  border-radius: 60px;
  padding: 10px;
}

.item {
  background-color: var(--mui-palette-bg-tertiary);
  border-radius: 57px;
  padding: 0px 8px;
}

.setting {
  /* background-color: var(--mui-palette-bg-secondary); */
  border-radius: 4px;
  padding: 10px 20px 0px 0px;
}

.settingItem {
  margin-bottom: 20px;
  display: flex;
  align-items: center;
  color: var(--mui-palette-text-primary);
  gap: 5px;
}

.itemImg {
  position: relative;
  align-items: center;
  display: flex;
  width: 32px;
  height: 32px;
  justify-content: center;
  filter: grayscale(1) brightness(2);
}

.itemTxt {
  width: auto;
  height: 20px;
  position: relative;
  color: var(--mui-palette-text-primary);
}

.gameBanner {
  display: flex;
  padding: 0px 4px 0px 6px;
  /* margin-left: -1%;
  margin-right: -2%; */
}

.width50 {
  float: left;
  height: auto;
  width: 50%;
  max-width: 100%;
  align-self: center;
}

.gameItemName {
  font-size: var(--text-md);
  font-weight: 300;
  margin-left: 3px;
}

.itemName {
  font-size: var(--text-md);
  font-weight: 300;
  margin-left: 3px;
}

.banner {
  width: 100%;
  height: auto;
}

.navLink {
  flex: 1;
  margin-bottom: -5px;
  margin-right: 10px;
}

.tab {
  /* color: rgba(255, 255, 255, 0.4); */
  color: #fff;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  font-size: 0.8rem;
}

.tab div {
  margin-top: 5px;
  text-align: center;
}

:global(.active) .tab div {
  color: #fff;
  font-weight: 700;
}

.support {
  width: 48%;
  background-color: var(--mui-palette-bg-tertiary);
  border-radius: 25px;
  height: 33px;
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 6px;
  padding: 0.5px 0px;
}

.support img {
  margin-right: 10px;
}

.support span {
  font-size: var(--text-md);
  font-family: 'OpenSans', sans-serif;
}

.cricketBg {
  width: 100%;
  height: auto;
  max-height: 1800px;
}

.closeIcon {
  position: absolute;
  top: 20px;
  right: 15px;
}

.downloadContainer {
  position: relative;
  background: linear-gradient(to bottom, #28beff, #059aff) !important;
  height: 45px;
  border-radius: 10px;
  display: flex;
  justify-content: center;
  align-items: center;
  margin-bottom: 10px;
}

.downloadBgImg {
  width: 100%;
  /* max-height: 65px; */
  height: auto;
}

.downloadContent {
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 15px;
}

.downloadImg {
  align-items: center;
  /* margin: 20px; */
}

.downloadTxt {
  text-transform: uppercase;
  /* font-family: Rowdies; */
  font-size: var(--text-md);
  font-weight: 700;
}

.liveSupportLangContext {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.lucky_wheel_img {
  width: 100%;
  height: auto;
}

.spinDiv {
  position: relative;
  /* display: flex; */
  /* height: auto;
  justify-content: center;
  margin-right: 10px; */
  width: 100%;
  height: fit-content;
  display: inline;
  margin: 0px 12px;
  width: 93%;
}

.spinCount {
  position: absolute;
  top: 14%;
  right: 6%;
  display: inline-block;
}

.spinCountBg {
  position: relative;
  display: flex;
  justify-content: center;
  align-items: center;
}

/* .spinCountBg img {
  width: 100%;
  height: auto;
} */

.spinCountDiv {
  position: absolute;
  top: 20px;
  right: 25px;
}

.spinImg {
  height: auto;
  position: relative;
  top: 8px;
  right: 65px;
}

.spinCountTxtDiv {
  position: absolute;
  display: flex;
  /* align-items: center;
  justify-content: center; */
  margin: 0;
  top: 50%;
  left: 50%;
  -ms-transform: translate(-50%, -50%);
  transform: translate(-50%, -50%);
}

.spinCountTxt {
  color: #000000;
  font-size: var(--text-sm);
  font-weight: 700;
  /* display: inline-block; */
}

.imgDiv {
  display: flex;
  flex-direction: column;
  width: 100%;
}

@media screen and (width > 520px) {
  .spinCount {
    top: 15%;
    right: 7%;
  }
  .spinCountBg img {
    width: 30px;
    height: 30px;
  }

  .spinCountTxt {
    font-size: var(--text-xl);
  }
}

@media screen and (width > 750px) {
  .spinDiv {
    width: 95%;
  }
}

@media screen and (max-width: 320px) {
  .spinDiv {
    width: 92%;
  }
}
